<template>
  <div
    class="overflow-hidden relative"
    :class="bgColor ?? 'bg-gray-400'"
  >
    <ImageBroken
      v-if="imageError"
      class="text-gray-200 opacity-50 h-full justify-center flex items-center"
      :size="72"
    />
    <template v-else>
      <CarvisSpinner
        v-if="!fullImgLoaded"
        class="absolute z-[2] p-2 max-w-40 max-h-40 opacity-50 m-auto left-0 right-0 bottom-0 top-0"
      />
      <img
        v-if="lowResImgSrc && !fullImgLoaded"
        class="absolute blur-sm w-full h-full"
        loading="lazy"
        v-bind="$attrs"
        :src="lowResImgSrc"
      >
      <img
        v-if="highResImgSrc"
        class="absolute h-full w-full"
        :class="{ 'z-[1]': fullImgLoaded } "
        loading="lazy"
        v-bind="$attrs"
        :src="highResImgSrc"
        @load="fullImgLoaded = true"
        @onerror="internalImageError = true"
      >
    </template>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { computed, ref } from 'vue'
import ImageBroken from 'vue-material-design-icons/ImageBroken.vue'

const props = defineProps<{
  lowResImgSrc?: string
  highResImgSrc: string | null
  imageError?: boolean
  bgColor?: string
}>()

const fullImgLoaded = ref(false)
const internalImageError = ref(props.imageError)
const imageError = computed(() => props.imageError || internalImageError.value)

watch(() => props.highResImgSrc, () => fullImgLoaded.value = false)
</script>
